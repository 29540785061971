/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap) => {

        // tabs section besoins
        const navItems = document.querySelectorAll(".tabs-nav-item");
        navItems.forEach((dom) => {
            dom.addEventListener('click', (e) => {
                if (e.target) {
                    navItems.forEach((b) => {
                        b.setAttribute('aria-expanded', false);
                    });

                    e.target.setAttribute('aria-expanded', true);
                    document.querySelectorAll('.tabs-item').forEach((p) => {
                        p.classList.remove('--active');
                    });
                    const panelId = e.target.getAttribute('aria-controls');
                    document.querySelector(`#${panelId}`).classList.add('--active');
                }
            });
        });
    }
}

