/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap, Swiper, Plyr, Overlay, customGoogleMap) => {

		/*
		|
		| Constants
		|------------
		*/
		const
			$contactForm = $('.contact-form')
			;

		/*
		|
		| Scroll menu fixed
		|-----------------
		*/
		var prevScrollpos = window.pageYOffset;
		window.onscroll = function () {
			var currentScrollpos = window.pageYOffset;
			if (prevScrollpos > currentScrollpos) {
				document.getElementById("header").style.top = "0";
				/*$(".sm-menu").slideUp();*/
			} else {
				/*document.getElementById("header").style.top = "-130px";*/
			}
			if (currentScrollpos > 50) {
				jQuery("header").removeClass('top');
				/*$(".sm-menu").slideUp();*/
			} else {
				jQuery("header").addClass('top');
			}

			prevScrollpos = currentScrollpos;

			if (currentScrollpos < 30) {
				document.getElementById("header").style.top = "0";
				/*$(".sm-menu").slideUp();*/
			}
		}

		/*
		|
		| Submenu
		|-----------------
		*/
		jQuery('.menu-item').click(function () {
			$(this).siblings(".menu-item").removeClass('active');
			$(this).siblings(".menu-item").find(".sm-menu").slideUp(200);

			$(this).toggleClass('active');
			$(this).find(".sm-menu").slideToggle(700);
		});




		/*
		|
		| Menu mobile
		|-----------------
		*/

		jQuery('#mobile-menu .menu-item-has-children').click(function () {
			$(this).toggleClass('active').find(".nav-drop").slideToggle(500);
			$(this).siblings(".menu-item-has-children").find(".nav-drop").slideUp(500);

			$(this).toggleClass('active');
			$(this).siblings(".menu-item-has-children").removeClass('active');
		});

		/**
		 * Plyr
		 * -----------------
		 */
		document.addEventListener('DOMContentLoaded', function() {
			const players = Array.from(document.querySelectorAll('.plyr__video-embed')).map(p => new Plyr(p));
		
			players.forEach(player => {
				const poster = player.elements.container.querySelector('.item-poster');
				const playIcon = player.elements.container.querySelector('.item-play-icon');
		
				player.on('play', () => {
					poster.style.opacity = '0';
				});
		
				playIcon.addEventListener('click', () => {
					player.play();
				});
			});
		});

		/*
		|
		| Chiffres
		|-----------------
		*/
		$('.key-figures').each(function () {
			const block = {
				wrapper: $(this),
				numbers: $(this).find('.number-counter'),
			};
			if (block.numbers.length) {
				block.timeline = gsap.timeline({
					scrollTrigger: {
						trigger: block.wrapper,
						start: 'top 80%'
					}
				});
				block.numbers.toArray().forEach((number) => {
					let counter = $(number);
					// console.log(counter.data('count'));
					let count = counter.data('count').toString().replace(',', '.').replace(' ', '');
					let zero = { val: 0 };
					let decimals = count.split('.')[1];
					let toFixed = decimals ? decimals.length : false;
					count = toFixed ? parseFloat(count) : parseInt(count);
					block.timeline.to(zero, {
						duration: 2,
						val: count,
						ease: 'power2.out',
						onUpdate: function () {
							let val = 0;
							if (toFixed) {
								let parts = zero.val.toFixed(toFixed).toString().split('.');
								parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
								val = parts.join(',')
							} else {
								val = Math.round(zero.val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
							}
							counter.text(val);
						}
					}, '-=.7');
				});
			}
		});

		/*
		|
		| Modal
		|-----------------
		*/
		if (jQuery('.mkx-modal-loop').length) {
			jQuery('.mkx-modal-loop').iziModal({});
		}

		/*
		|
		| City slider
		|-----------------
		*/
		if (jQuery('.slider-city').length) {

			function marqueelike() {
				$('.slider-city').each(function () {
					var texte = $(this).html();
					$(this).html('<div><span>' + texte + '</span><span>' + texte + '</span></div>');
				});
			}
			$(window).on('load', function () {
				marqueelike();
			});
		}

		/*
		|
		| Anchor
		|-----------
		*/
		jQuery(document).ready(function () {
			if ($(".block-list-anchor")[0]) {
				$(window).scroll(function () {
					var height = $(window).scrollTop();
					if (height >= 510) {
						if (!$(".notify").length) {
							jQuery("body").find(".anchor-fixed").css({ top: '0px' });
						}
						$(".block-list-anchor").addClass("anchor-fixed");
					} else {
						if (!$(".notify").length) {
							jQuery("body").find(".anchor-fixed").css({ top: '0' });
						}
						$(".block-list-anchor").removeClass("anchor-fixed");
					}
				});
			}
		});

		jQuery(".list-anchor .item").click(function () {
			jQuery(".list-anchor .item").removeClass("active");
			jQuery(this).addClass("active");
		});


		/*
		|
		| Anchors
		|-----------------
		*/
		var sections = $('.blockanchor');
		var nav = $('.block-list-anchor');
		var nav_height = nav.outerHeight();
		var offsettop = 200;

		$(window).on('scroll', function () {
			var cur_pos = $(this).scrollTop();

			sections.each(function () {
				var top = $(this).offset().top - nav_height - offsettop,
					bottom = top + $(this).outerHeight();

				if (cur_pos >= top - 5) {
					nav.find('a').removeClass('active');
					sections.removeClass('active');

					$(this).addClass('active');
					nav.find('a[href="#' + $(this).attr('id') + '"]').addClass('active');
				}
			});
		});

		nav.find('a').on('click', function () {
			var $el = $(this)
				, id = $el.attr('href');

			$('html, body').animate({
				scrollTop: $(id).offset().top - nav_height - offsettop
			}, 500);

			return false;
		});


		/*
		|
		| Scroll load anchors
		|-----------------
		*/
		$(window).on("load", function () {
			let offset_top = 200;
			let urlHash = window.location.href.split("#")[1];
			if (urlHash && $('#' + urlHash).length)
				$('html,body').animate({
					scrollTop: $('#' + urlHash).offset().top - offset_top
				}, 1000);
		});


		/*
		| 
		| Slider témoignage
		|-----------
		*/
		$(".swiper-temoignage").each(function (i) {
			var mySwiper = new Swiper($(this)[0], {
				slidesPerView: 1,
				speed: 1000,
				spaceBetween: 40,
				navigation: {
					nextEl: $(".button-next")[i],
					prevEl: $(".button-prev")[i],
				}
			});
		});

		function playVideo() {
			var iframe = document.getElementById('youtubeIframe');
			var cover = document.querySelector('.video-cover');
			var src = iframe.getAttribute('data-src');
			iframe.setAttribute('src', src + "&autoplay=1");
			cover.style.display = 'none';
		}

		/*-------- Lazy loading --------*/
		document.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))

		/*-------- Modals --------*/
		document.querySelectorAll('.modal').forEach((item) => {
			app.overlays[item.id] = new Overlay(item)
		})
	}
}
