/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
require('@lib/iziModal/js/iziModal.js')($);
import gsap from 'gsap';
import SplitText from '@lib/gsap-pro/SplitText';
import ScrollTo from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollTo);
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

/*
|
| Importing Components
|-----------------------
*/
import customGoogleMap from '@components/custom-google-map';
import Kira from '@components/kira';
import Menu from '@components/menu';
import Overlay from '@components/overlays';
import Plyr from '@components/plyr.min.js';
import MobileDetect from '@components/mobile-detect'

/*
|
| Importing Utils
|-------------------
*/
import * as app from '@utils/global';
import Router from '@utils/router';
import Ajax from '@utils/ajax';

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main';
import news from '@pages/news';
import animations from '@pages/animations';
import home from '@pages/home';
import actualites from '@pages/actualites.js';
import presse from '@pages/presse.js';

window.MD = new MobileDetect()

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        file: animations,
        dependencies: [app, gsap, ScrollTrigger, SplitText, Menu, Kira]
    },
    {
        file: main,
        dependencies: [app, gsap, Swiper, Plyr, Overlay, customGoogleMap],
    },
    {
        file: news,
        dependencies: [app, gsap, Ajax],
        resolve: '#page-news-archive'
    },
    {
        file: actualites,
        dependencies: [app],
        resolve: '#page-actualites-archive'
    },
    {
        file: presse,
        dependencies: [app],
        resolve: '#page-presse-archive'
    },
    {
        file: home,
        dependencies: [app, gsap],
        resolve: '#page-home'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

