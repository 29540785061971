/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, Ajax) => {

        /*
        |
        | Constants
        |------------
        */
        const
            loadMoreBtn = document.querySelector('.load-more-btn'),
            postsContainer = document.querySelector('.loaded-posts'),
            loadMoreContainer = document.querySelector('.load-more-container'),
            loadMore = document.querySelector('.load-more'),
            loaderContainer = document.querySelector('.loader-container')
        ;
 
        let xhr = null

        /*
        |
        | Load more ajax
        |--------------
        */
        loadMoreBtn.addEventListener('click', (e) => {
            e.preventDefault()

            if(xhr) xhr.abort()

            const offset = postsContainer.querySelectorAll('.custom-card').length;
            const url = `/ajax/posts/${offset}/`;

            xhr = new Ajax({
                url: url,
                onLoadStart: () => {
                    gsap.to(loadMore, {
                        y: 30,
                        autoAlpha: 0,
                        display: 'none',
                        duration: .5,
                        ease: 'Power1.easeOut',
                        onComplete: () => {
                            gsap.fromTo(loaderContainer, {
                                y: 30,
                                autoAlpha: 0,
                                display: 'none'
                            }, {
                                y: 0,
                                autoAlpha: 1,
                                display: 'block',
                                duration: .5,
                                ease: 'Power1.easeOut'
                            })
                        }
                    })
                },
                onSuccess: (response) => {
                    postsContainer.innerHTML += response

                    gsap.to(loaderContainer, {
                        y: 50,
                        autoAlpha: 0,
                        display: 'none',
                        duration: .5,
                        ease: 'Power1.easeOut'
                    })

                    if(postsContainer.querySelector('.no-more-post')) {
                        loadMoreContainer.remove()
                    } else {
                        gsap.to(loadMore, {
                            y: 0,
                            autoAlpha: 1,
                            display: 'block',
                            duration: .5,
                            ease: 'Power1.easeOut'
                        })
                    }
                }
            })            
        })
    }
}
